<template>
  <b-col lg="4" class="mb-3">
    <div v-if="hasValue">
      <b-card body-class="p-0" footer-class="p-0 overflow-hidden">
        <template #header>
          <card-header
            :title="report.value"
          />
        </template>
        <div v-if="hasValue" class="text-center">
          <line-chart-card
            :current-filter="currentFilter"
            :search-data="searchData"
            :dataset="dataset"
            :chart-id="report.key"
            hour
            day
            week
          ></line-chart-card>
        </div>
        <div v-else class="chart-unavailable">
          <span class="chart-unavailable__msg">
            Informazioni non disponibili
          </span>
        </div>
        <template #footer>
          <div v-if="checkValue" class="chart__progress-bars">
            <div :style="positiveColWidth">
              <b-progress
                :value="posValue"
                :max="posValue"
                show-value
                variant="success"
                class="progress--is-squared"
              >
                <b-progress-bar :value="posValue">
                  {{posValueLabel}}
                </b-progress-bar>
              </b-progress>
            </div>
            <div :style="negativeColWidth">
              <b-progress
                :value="negValue"
                :max="negValue"
                show-value
                variant="danger"
                class="progress--is-squared"
              >
                <b-progress-bar :value="negValue">
                  {{negValueLabel}}
                </b-progress-bar>
              </b-progress>
            </div>
          </div>
          <div v-else class="chart-unavailable__count">
            <span>N/A</span>
          </div>
        </template>
      </b-card>
    </div>
    <div v-else class="d-flex h-100 flex-column">
      <b-card class="h-100" body-class="p-0 position-relative has-chart" footer-class="p-0 overflow-hidden">
        <template #header>
          <card-header
            :title="report.value"
          />
        </template>
        <div class="chart-unavailable">
          <span class="chart-unavailable__msg">
            Informazioni non disponibili
          </span>
        </div>
        <template #footer>
          <div v-if="hasValue" class="chart-unavailable__count">
            <span>N/A</span>
          </div>
        </template>
      </b-card>
    </div>
  </b-col>
</template>

<script>

import { isNotEmpty } from '../../utils/validators';

const LineChartCard = () => import('@/components/chart/LineChartCard.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'FeedbackSectionCdv',
  components: { LineChartCard, CardHeader },
  props: {
    report: {
      key: String,
      value: String,
      hasValues: Boolean,
      feedbackValues: {
        countTotal: Number,
        countPositive: Number,
        countNegative: Number,
        sectionPoint: Number,
        averagePoint: Number,
      },
    },
    personId: String,
    reportValues: Object,
  },
  data() {
    return {
      currentFilter: 'hour',
      loading: false,
      dataset: [],
      hasError: false,
    };
  },
  mounted() {
    this.searchData(this.currentFilter);
  },
  computed: {
    hasValue() {
      console.log('value check', this.reportValues);
      return this.reportValues !== {};
    },
    checkValue() {
      return isNotEmpty(this.reportValues?.value);
    },
    countError() {
      const pos = this.reportValues.value.countPositive;
      const neg = this.reportValues.value.countNegative;
      const tot = this.reportValues.value.countTotal;
      return (pos + neg) < tot || (pos + neg) > tot;
    },
    posValue() {
      if (this.countError) return 50;
      return this.reportValues?.value?.countPositive ? this.reportValues.value.countPositive : 0;
    },
    posValueLabel() {
      return this.reportValues?.value?.countPositive ? this.reportValues.value.countPositive : 0;
    },
    negValue() {
      if (this.countError) return 50;
      return this.reportValues?.value?.countNegative ? this.reportValues.value.countNegative : 0;
    },
    negValueLabel() {
      return this.reportValues?.value?.countNegative ? this.reportValues.value.countNegative : 0;
    },
    positiveColWidth() {
      // console.log('report section', this.report);
      // console.log('pos', (this.report.feedbackValues.countPositive / this.report.feedbackValues.countTotal) * 100);
      if (this.countError) return 'width: 50%';
      if (this.reportValues?.value) return `width: ${(this.reportValues.value.countPositive / this.reportValues.value.countTotal) * 100}%`;
      return 'width: 50%';
    },
    negativeColWidth() {
      // console.log('neg', this.report.feedbackValues.countTotal, this.report.feedbackValues.countNegative);
      if (this.countError) return 'width: 50%';
      if (this.reportValues?.value) return `width: ${(this.reportValues.value.countNegative / this.reportValues.value.countTotal) * 100}%`;
      return 'width: 50%';
    },
  },
  methods: {
    searchData(filter) {
      if (!this.loading) {
        this.loading = true;
        this.hasError = false;
        this.currentFilter = filter;
        const entityType = 'person';
        const entityId = this.personId;
        // console.log('fetch report check', entityType, entityId, filter, this.report.key);
        this.$store.dispatch('score/fetchReportHistoryStatistics', {
          entityType, entityId, interval: filter, type: this.report.key,
        })
          .then((response) => {
            // console.log('fetchReportHistoryStatistics fetch', response);
            this.dataset = response.map((item) => ({ x: new Date(item.date), y: item.totalReport }));
            // console.log('fetchReportHistoryStatistics this.dataset', this.dataset);
            this.loading = false;
          }, (error) => {
            console.log('fetchReportHistoryStatistics Error fetch', error);
            this.loading = false;
            this.hasError = true;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
